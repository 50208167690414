@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.css-953pxc-MuiInputBase-root-MuiInput-root::before{
    border: none !important;
  }

.css-4oe8v1-MuiFormControl-root-MuiTextField-root .MuiFormHelperText-root {
    font-size: 12px !important;
}

.css-4oe8v1-MuiFormControl-root-MuiTextField-root {
    border-bottom: 2px solid #e7e7e7 !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    font-size: 1.1rem;
}
.css-14lo706>span{
    font-size: 1.25rem;
}
.google-login-button {
    font-size: 20px !important; /* Increase the font size */
    width: 300px; /* Optional: Set the width */
    height: 50px; /* Optional: Set the height */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .google-login-button div {
    font-size: 20px !important; /* Ensure the font size applies to inner elements */
  }

  .img-bg{
    background-image: url('./assets/images/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    
  }
/* src/index.css */

/* Hamburger icon transition */
.hamburger span {
  transition: transform 0.3s, opacity 0.3s;
}

/* Open state for hamburger icon */
.hamburger.open span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}
.hamburger.open span:nth-child(2) {
  opacity: 0;
}
.hamburger.open span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Menu container transition */
.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 320px; /* Adjust width as needed */
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

/* Visible menu */
.menu.open {
  transform: translateX(0);
}


 .font-noto{
  font-family: 'Noto Sans JP', sans-serif !important;
}

.MuiTypography-root{
  font-family: 'Noto Sans JP', sans-serif !important;

}



.input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.input-wrapper .icon {
  position: absolute;
  left: 10px;
  font-size: 1.5rem;
}

.input-wrapper .StripeElement {
  padding-left: 2rem; /* Adjust based on the size of the icon */
  width: 100%;
}

/* import CreditCardIcon from '@mui/icons-material/CreditCard'; */


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000000 !important;
}